import * as React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import pic1 from "./photography/untitledbodyproject/1_body.jpg";
import pic2 from "./photography/untitledbodyproject/2_body.jpg";
import pic3 from "./photography/untitledbodyproject/3_body.jpg";
import pic4 from "./photography/untitledbodyproject/4_body.jpg";
import pic5 from "./photography/untitledbodyproject/5_body.jpg";
import pic6 from "./photography/untitledbodyproject/6_body.jpg";
import pic7 from "./photography/untitledbodyproject/7_body.jpg";
import pic8 from "./photography/untitledbodyproject/8_body.jpg";
import pic9 from "./photography/untitledbodyproject/bodyexpo.png";
import Footer from "../Footer";

export default function UntitledBodyProject() {
    const theme = createTheme({
        typography: {
            h1: {
                fontFamily: "Helvetica",
                fontSize: "3rem"
            },
            h2: {
                fontFamily: "Helvetica",
                fontSize: "2rem"
            }
        }
    });

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto'
    };

    const themeForBreakpoints = createTheme();

    const sm = useMediaQuery(themeForBreakpoints.breakpoints.down('sm'));
    const lg = useMediaQuery(themeForBreakpoints.breakpoints.up('lg'));
    const tablet = useMediaQuery('(min-width:768px) and (max-width:1000px)');
    let images = [pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8];

    const [open, setOpen] = React.useState(false);
    const [modalData, setModalData] = React.useState(null);
    const [imageHeight, setImageHeight] = React.useState(null);
    const [imageWidth, setImageWidth] = React.useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Manual spacing adjustments
    const containerWidth = "80vw";
    const titleMargin = "2vw";
    const titleSpacing = "4vw";
    const textSpacing = "4vw";

    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin="0 auto"
                padding="0"
                width={containerWidth}
                boxSizing="border-box"
                flexDirection="column"
                minHeight="100vh"
            >
                <Box flex="1">
                    <Typography 
                        style={{ 
                            color: 'black', 
                            textDecoration: 'none', 
                            fontSize: "2rem", 
                            textAlign: "center",
                            margin: `${titleMargin} 0` 
                        }}
                    >
                        // untitled body project
                    </Typography>
                    
                    <Typography 
                        style={{ 
                            fontSize: "1rem", 
                            marginBottom: "2vw",
                            textAlign: "center"
                        }}
                    >
                        Project realized during a workshop with <a href="https://sabinabosch.com" target="blank">Sabina Bösch</a>.
                    </Typography>

                    <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
                        {images.map((image) => {
                            if (tablet) {
                                return (
                                    <Grid item xs={6} key={image} style={{ width: "80vw", display: "flex", justifyContent: "center" }}>
                                        <img src={image} style={{ width: "100%" }} />
                                    </Grid>
                                );
                            }
                            else if (sm) {
                                return (
                                    <Grid item xs={12} key={image} style={{ width: "80vw", display: "flex", justifyContent: "center" }}>
                                        <img src={image} style={{ width: "100%" }} />
                                    </Grid>
                                );
                            }
                            else {
                                return (
                                    <Grid item xs={3} key={image} style={{ display: "flex", justifyContent: "center" }}>
                                        <Button onClick={() => {
                                            setModalData(image);
                                            handleOpen();
                                            const img = new Image();
                                            img.src = image;
                                            img.onload = () => {
                                                setImageHeight(img.height);
                                                setImageWidth(img.width);
                                            };
                                        }}>
                                            <img src={image} style={{ width: "100%" }} />
                                        </Button>
                                    </Grid>
                                );
                            }
                        })}
                    </Grid>

                    <Typography 
                        style={{ 
                            fontSize: "1rem", 
                            marginBottom: "2vw", 
                            marginTop: "2vw",
                            textAlign: "center"
                        }}
                    >
                        ECAL Leonid Pesenti © 2025 <br></br>
                    </Typography>

                    {/* Centered pic9 */}
                    <Box display="flex" justifyContent="center" marginBottom="4vw">
                        <img src={pic9} alt="Body Expo" style={{ width: "80%", maxWidth: "400px", height: "auto" }} />
                    </Box>
                </Box>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <img src={modalData} height={imageHeight > 900 ? imageHeight * 0.50 : "100%"} width={imageHeight > 900 ? imageWidth * 0.50 : "100%"} />
                    </Box>
                </Modal>

                <Footer />
            </Box>
        </ThemeProvider>
    );
}
