import * as React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { ThemeProvider, createTheme, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import pic from './Photographies/photography/tq1yA46.jpeg';
import Footer from "./Footer.jsx";

export default function Music() {
    const theme = createTheme({
        typography: {
            h1: {
                fontFamily: "Helvetica",
                fontSize: "3rem"
            },
            h3: {
                fontFamily: "Helvetica",
                fontSize: "1rem"
            }
        }
    });

    const themeForBreakpoints = createTheme();

    const sm = useMediaQuery(themeForBreakpoints.breakpoints.down('sm'));
    const tablet = useMediaQuery('(min-width:768px) and (max-width:1000px)');

    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="start"
                height="88vh"
                marginTop="5vh"
                maxWidth="100vw"
            >
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="100vw">
                    <Grid item xs={12}>
                        <Typography variant="h1">// music</Typography>
                    </Grid>
                    <Grid item xs={12}  margin = "5vw" marginTop="2vw" marginBottom={!sm?'5vw':'0'}width={'80vw'} >
                    <iframe class="border-radius:12px" src="https://open.spotify.com/embed/album/4krJPIG49xhlUKRfXY8uPW?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                    <iframe class="border-radius:12px" src="https://open.spotify.com/embed/album/474jZCJgBz5pDy5t5UP4Um?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                    <iframe class="border-radius:12px" src="https://open.spotify.com/embed/track/5peE6ExzxI6VmRhQ9eFFzv?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                    <iframe class="border-radius:12px" src="https://open.spotify.com/embed/album/3rgLFMBENzCantkP9QqBs8?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                    <iframe class="border-radius:12px" src="https://open.spotify.com/embed/track/1mawI7kC44LlDTQ4YPuH42?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                    <iframe class="border-radius:12px" src="https://open.spotify.com/embed/track/5MGHJY95e95HXtzhLdokT3?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                    </Grid>
                </Grid>
            </Box>
            <Footer fixed></Footer>
        </ThemeProvider>
    );
}