import React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import pic_1 from './Photographies/photography/events/backstreetnoise240525-theneue.png';
import pic_2 from './Photographies/photography/events/cafexx240607.jpg';
import pic_3 from './Photographies/photography/events/attheedge.gif';
import pic_4 from './Photographies/photography/events/ebullition_1.png';
import Footer from "./Footer.jsx";
import './styles.css'; // Adjust path based on your file structure

export default function Events() {
    const theme = createTheme({
        typography: {
            h1: {
                fontFamily: "Helvetica",
                fontSize: "3rem"
            },
            h3: {
                fontFamily: "Helvetica",
                fontSize: "1rem"
            }
        }
    });

    const isDesktop = useMediaQuery('(min-width:600px)'); // Check if screen is desktop size

    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="start"
                alignContent="center"
                height="88vh"
                marginTop="5vh"
                maxWidth="100vw"
            >
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="100vw">
                    
                    <Grid item xs={12}>
                        <Typography variant="h1">// events</Typography>
                    </Grid>
                    
                    <Typography style={{ fontSize: "1rem", marginTop: "2vh", padding: "1vh", marginBottom: "2vh" }}>
                        some of my recorded DJ sets and live events
                    </Typography>

                    {/* SoundCloud Embed */}
                    <Grid item xs={12} width="90vw" marginBottom="2vh">
                        <iframe
                            width="100%"
                            height="450"
                            scrolling="no"
                            frameBorder="no"
                            allow="autoplay"
                            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1831730748&color=%230a0a0a&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                        ></iframe>
                        <div style={{
                            fontSize: '10px',
                            color: '#cccccc',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            fontFamily: 'Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Verdana, Tahoma, sans-serif',
                            fontWeight: 100,
                        }}>
                            <a href="https://soundcloud.com/leon1d" title="Leonid" target="_blank" 
                                style={{ color: '#cccccc', textDecoration: 'none' }}>
                                Leonid
                            </a> ·
                            <a href="https://soundcloud.com/leon1d/sets/mixes" title="Mixes" target="_blank" 
                                style={{ color: '#cccccc', textDecoration: 'none' }}>
                                Mixes
                            </a>
                        </div>
                    </Grid>

                    {/* Event Posters - Even Smaller on Desktop */}

                    <Typography style={{ fontSize: "2rem", marginTop: "2vh", padding: "1vh", marginBottom: "1vh" }}>
                       <b>UPCOMING</b>
                    </Typography>

                    {[pic_4].map((pic, index) => (
                        <Grid item xs={12} 
                        style={{ 
                            width: isDesktop ? "35vw" : "80vw", // Smaller on desktop
                            display: "flex", 
                            justifyContent: "center", 
                            alignItems: "center", 
                            overflow: "hidden", 
                            marginTop: "2vw"
                        }}
                    >
                        <a href="https://www.petzi.ch/fr/events/59044-ebullition-violent-magic-orchestra-jp-goffbaby-live-ch-couloir-gang-ch-i-date-models-ch-ebullition/" target="_blank" rel="noopener noreferrer">
                            <img src={pic_4} alt="Event Poster 1" 
                                style={{ width: "100%", height: "auto", maxWidth: "100%" }} 
                            />
                        </a>
                    </Grid>
                    ))}

<                   Typography style={{ fontSize: "2rem", marginTop: "5vh", padding: "1vh", marginBottom: "1vh" }}>
                       <b>PAST</b>
                    </Typography>

                    {[pic_3, pic_2, pic_1].map((pic, index) => (
                        <Grid key={index} item xs={12} 
                            style={{ 
                                width: isDesktop ? "35vw" : "80vw", // Smaller on desktop
                                display: "flex", 
                                justifyContent: "center", 
                                alignItems: "center", 
                                overflow: "hidden", 
                                marginTop: "2vw" 
                            }}
                        >
                            <img src={pic} alt={`Event Poster ${index + 1}`} 
                                style={{ width: "100%", height: "auto", maxWidth: "100%" }} 
                            />
                        </Grid>
                    ))}

                </Grid>
            </Box>
            <Footer fixed></Footer>
        </ThemeProvider>
    );
}
